<template>
  <modal
    :show="show"
    title="Преимущества подписки"
    @hide="() => $emit('hide')"
    wrapClass="modal-md"
    contentClass="p-4 overflow-auto"
    ><div style="width: 700px">
      <b-embed type="iframe" aspect="16by9" src="" allowfullscreen></b-embed>
    </div>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  props: {
    show: Boolean,
  },
  components: {
    Modal,
  },
};
</script>

<style lang="css" scoped></style>
