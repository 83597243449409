<template>
  <div>
    <h2 class="my-4" style="font-size: 24px">Сравнение тарифов</h2>
    <!--div class="hovering__pad p-4 mb-5 d-md-none" id="demo-sub">
      <div class="d-flex justify-content-start align-items-center mb-3">
        <img
          class="d-inline-block mr-3"
          style="width: 34px; height: 23px"
          src="@main/assets/img/icons/ut_icon.png"
          alt=""
        />
        <p>Преимущества подписки</p>
      </div>
      <b-embed type="iframe" aspect="16by9" src="" allowfullscreen></b-embed>
      <div class="mt-3 d-flex justify-content-center justify-content-sm-start">
        <b-button v-hideon="'demo-sub'" variant="link" class="mx-auto"
          >Больше не показывать</b-button
        >
      </div>
    </!--div-->
    <div class="hovering__pad">
      <b-table :fields="fields" :items="items" striped responsive>
        <template #head(advantage)="data">
          <div style="min-width: 230px" class="d-md-block d-none">
            <b-button
              @click="showAdvModal = true"
              class="mx-auto"
              size="lg"
              variant="outline-default"
            >
              <img
                class="d-inline-block mr-2"
                style="width: 34px; height: 23px"
                src="@main/assets/img/icons/ut_icon.png"
                alt=""
              />{{ data.label }}</b-button
            >
          </div>
        </template>
        <template #head(free)="data">
          <div style="min-width: 150px" class="text-center">
            <p style="font-size: 18px">{{ data.label }}</p>
            <p style="font-size: 20px; font-weight: 500">0 ₽</p>
          </div>
        </template>
        <template #head(payed)="data">
          <div style="min-width: 150px" class="text-center">
            <p style="font-size: 18px">{{ data.label }}</p>
            <p style="font-size: 20px; font-weight: 500">2990₽ в месяц</p>
          </div>
        </template>
        <template #cell(advantage)="data">
          <div
            v-if="isMobile && data.item.advantageMobile && data.item.descriptionMobile"
            class="d-flex flex-row justify-content-between align-items-center"
          >
            <div class="fs-16 position-relative" v-html="data.item.advantageMobile"></div>
            <div
              v-b-tooltip.html
              :title="data.item.descriptionMobile"
              class="text-center align-middle ml-3"
              style="
                font-size: 13px;
                line-height: 20px;
                background: #b4bdc1;
                border-radius: 100%;
                min-width: 20px;
                width: 20px;
                height: 20px;
              "
            >
              ?
            </div>
          </div>
          <div v-else class="fs-16 position-relative" v-html="data.value"></div>
        </template>
        <template
          v-slot:[`cell(${field.key})`]="data"
          v-for="field in fields.filter((val) => val.bool)"
        >
          <div class="d-flex justify-content-center" :key="`${field.key}dajdhNI*8t1`">
            <img v-if="data.value" src="@main/assets/img/svg/yes.svg" />
            <img v-else src="@main/assets/img/svg/no.svg" />
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-start align-items-center pb-4 pt-1 px-4 d-md-none">
        <b-button
          v-if="!currentUser"
          @click="() => toggleSignIn(true)"
          class="mr-3"
          variant="outline-default"
          >Зарегистрироваться</b-button
        >
        <b-button href="https://sub.easyprbot.com/" target="_blank" variant="yellow"
          >Купить подписку</b-button
        >
      </div>
      <div class="d-none d-md-flex justify-content-end align-items-center pb-4 pt-1 px-4">
        <b-button
          v-if="!currentUser"
          @click="() => toggleSignIn(true)"
          class="mr-3"
          size="lg"
          variant="outline-default"
          >Зарегистрироваться</b-button
        >
        <b-button href="https://sub.easyprbot.com/" target="_blank" size="lg" variant="yellow"
          >Купить подписку</b-button
        >
      </div>
    </div>
    <SubscriptionAdvantagesModal :show="showAdvModal" @hide="showAdvModal = false" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import SubscriptionAdvantagesModal from '@main/components/reusable/modals/SubscriptionAdvantagesModal.vue';

export default {
  components: {
    SubscriptionAdvantagesModal,
  },
  data: () => ({
    showAdvModal: false,
    items: [
      { advantage: 'Публикация отзывов на сайте', free: true, payed: true },
      {
        advantage: '3 бесплатных размещений в чатах, прикрепленных к @easyprbot',
        free: true,
        payed: true,
      },
      {
        advantage: `<p class="fs-16">Открытая статистика блогера</p>
        <p style="font-size: 14px" class="gray-text">В бесплатной версии другому пользователю нужно будет потратить 1 проверку блогера, чтобы посмотреть карточку блогера со статистикой и опционально форматами рекламы</p>`,
        free: false,
        payed: true,
        advantageMobile: '<p class="fs-16">Открытая статистика блогера</p>',
        descriptionMobile:
          'В бесплатной версии другому пользователю нужно будет потратить 1 проверку, чтобы посмотреть карточку блогера со статистикой и опционально форматами рекламы',
      },
      {
        advantage: '<strong>+30 размещений в месяц</strong> в чатах, прикрепленных к @easyprbot',
        free: false,
        payed: true,
      },
      {
        advantage: `<strong>Трекер рекламы</strong>
                    <div>
                    - Снизьте цену за подписчика до 72% и проверьте окупаемость рекламы еще на этапе выбора блогеров по их реальной эффективности<br>
                    - Сократите расходы на рекламу до 57% без потерь с калькулятором подписчиков<br>
                    - Cнизьте цену за подписчика
                    и улучшите рекламную подачу с инструментом аналитики<br>
                    - Сохраняйте лучших блогеров и управляйте заказом рекламы в одной таблице </div>`,
        free: false,
        payed: true,
        advantageMobile: `<strong>Трекер рекламы</strong><br>
                          Снизьте цену за подписчика и бюджет на рекламу без потери числа подписчиков`,
        descriptionMobile: `<strong>Трекер рекламы</strong>
                            <div>
                            - Снизьте цену за подписчика до 72% и проверьте окупаемость рекламы еще на этапе выбора блогеров по их реальной эффективности<br>
                            - Сократите расходы на рекламу до 57% без потерь с калькулятором подписчиков<br>
                            - Cнизьте цену за подписчика
                            и улучшите рекламную подачу с инструментом аналитики<br>
                            - Сохраняйте лучших блогеров и управляйте заказом рекламы в одной таблице </div>`,
      },
      {
        advantage:
          '<strong>Проверка блогеров</strong> по 30 чатам активностей, чёрным спискам, рекомендациям к проверке на накрутку, отзывам и карточкам со статистикой и ценами блогеров',
        free: false,
        payed: true,
        advantageMobile: `<strong>Проверка блогеров</strong> по 30 чатам активностей`,
        descriptionMobile: `Проверка блогеров по 30 чатам активностей, чёрным спискам, рекомендациям к проверке на накрутку, отзывам и карточкам со статистикой и ценами блогеров`,
      },
      {
        advantage:
          '<strong>Авторазмещение в телеграм-боте Easyprbot</strong> и прием заявок на рекламу из него',
        free: false,
        payed: true,
      },
      {
        advantage:
          '<strong>Доступ к таблице</strong> со средней ценой за подписчика для разных тем',
        free: false,
        payed: true,
      },
      {
        advantage: 'Техническая поддержка',
        free: false,
        payed: true,
      },
    ],
    isMobile,
  }),
  methods: {
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
  },
  computed: {
    ...mapState(['currentUser']),
    fields() {
      return [
        // A column that needs custom formatting
        {
          key: 'advantage',
          label: 'Преимущества подписки',
          thClass: 'align-middle',
          tdClass: 'p-2 px-3 p-sm-3 px-sm-4 mw-250',
        },
        {
          key: 'free',
          label: 'Бесплатная версия',
          bool: true,
          class: `align-middle ${isMobile ? 'mw-200' : 'mw-250'}`,
        },
        {
          key: 'payed',
          label: 'Платная версия',
          bool: true,
          tdClass: `align-middle ${isMobile ? 'mw-200' : 'mw-250'}`,
          thClass: `bg-yellow ${isMobile ? 'mw-200' : 'mw-250'}`,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table td:not(:first-child) {
  border-left: 1px solid #dee2e6;
}

::v-deep .table th:not(:first-child) {
  border-left: 1px solid #dee2e6;
}

::v-deep .table th {
  border-top: none;
  min-width: 180px !important;
}
::v-deep .table {
  margin-top: none !important;
  border-bottom: 1px solid #dee2e6;
}
::v-deep .tooltip-inner {
  position: absolute !important;
  min-width: 200px !important;
  font-size: 12px !important;
  background-color: white !important;
  color: black !important;
  opacity: 1 !important;
  border: 1px solid #3897f0;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  border-radius: 5px;
}
::v-deep .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
::v-deep .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0);
}
</style>
